<template>
  <div v-if="!isLoading" class="requirement">
    <Back :to="`/eir-sets/${$route.params.setId}`">Назад</Back>

    <header class="mb-2">
      <div class="d-flex">
        <h1>{{ name }}</h1>
        <v-btn
          text
          icon
          small
          title="Изменить"
          alt="Изменить"
          color="primary"
          class="ml-2 mt-1"
          @click="$refs.modalEir.show(eirElementInfo, 'update', false)"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </div>

      <div class="d-flex flex-row align-center">
        <span class="caption mr-1">Требование</span>
        <v-chip small label outlined color="primary" class="mr-1">
          <span class="font-weight-bold">Изменено:&nbsp;</span>
          {{ updatedAt | formatDate }}
        </v-chip>
      </div>
    </header>

    <div v-if="description" class="my-3">
      {{ description }}
    </div>

    <div class="d-sm-flex align-sm-end" style="min-height: 54px">
      <v-btn
        v-if="tab === 0"
        class="order-sm-1 mb-sm-1"
        large
        color="primary"
        elevation="10"
        @click="$refs.modalEirParams.show()"
      >
        <v-icon class="mr-1" size="17">mdi-attachment-plus</v-icon>
        Привязать параметры
      </v-btn>

      <v-btn
        v-if="tab === 0"
        class="ml-2 order-sm-1 mb-sm-1"
        large
        color="primary"
        elevation="10"
        @click="$refs.modalSection.show()"
      >
        <v-icon class="mr-1" size="17">mdi-folder-plus-outline</v-icon>
        Привязать раздел
      </v-btn>

      <v-tabs ref="tabs" v-model="tab" class="tabs order-sm-0">
        <v-tab>
          Параметры требования ({{ eirElementInfo.parameters?.length }})
          <v-btn
            text
            icon
            x-small
            title="Редактирование параметров набора"
            color="primary"
            class="ml-1"
            :to="`/eir-sets/${setId}?tab=1`"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-tab>
        <v-tab>Фильтр</v-tab>
      </v-tabs>
    </div>
    <v-divider class="mb-3" />

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TabEirParams class="mb-10" />
      </v-tab-item>

      <v-tab-item>
        <SimpleFilter
          class="mb-10"
          is-sandbox
          :valid.sync="filterFormIsValid"
          @apply="({ conditions }) => (filterConditions = conditions)"
        />

        <v-card outlined height="100%">
          <v-card-title>Элементы из САПРа согласно фильтру</v-card-title>
          <SaprElements
            ref="saprElements"
            :is-loading="isLoading || checking"
            :items="revitElements"
            :eir-element="eirElementInfo"
            is-sandbox
          >
            <template #header>
              <div class="d-flex">
                <div class="mr-2">
                  <ChangeFileBlock />
                </div>
                <div>
                  <FilterHeader
                    ref="filterHeader"
                    :eir-element="eirElementInfo"
                    is-sandbox
                    class="mb-2"
                    :disabled="!filterFormIsValid"
                    :conditions="filterConditions"
                    :loading="checking"
                  />
                </div>
              </div>
            </template>
          </SaprElements>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <ModalEir ref="modalEir" />
    <ModalEirParams ref="modalEirParams" />
    <ModalSection ref="modalSection" />
  </div>
  <v-progress-linear v-else class="loader" indeterminate color="primary" />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SimpleFilter from '@/components/SimpleFilter/SimpleFilter'
import Back from '@/components/Back'
import ChangeFileBlock from '@/components/ChangeFileBlock'
import ModalSection from '@/components/ModalSection'

import SaprElements from '@/views/ProjectEir/partials/SaprElements'
import FilterHeader from '@/views/ProjectEir/partials/FilterHeader'

import ModalEir from '@/views/EirSetItem/partials/ModalEir'

import TabEirParams from './partials/TabEirParams'
import ModalEirParams from './partials/ModalEirParams'

export default {
  name: 'Eir',
  components: {
    ChangeFileBlock,
    Back,
    TabEirParams,
    SimpleFilter,
    ModalEir,
    ModalEirParams,
    SaprElements,
    FilterHeader,
    ModalSection,
  },
  data: () => ({
    tab: 0,
    isLoading: false,
    filterFormIsValid: false,
    filterConditions: [],
  }),
  computed: {
    ...mapState('eirElements', ['eirElementInfo']),
    ...mapGetters('revit', ['getTestElementsById', 'getTestCheckStatusById']),
    name: (vm) => vm.eirElementInfo.name,
    description: (vm) => vm.eirElementInfo.description,
    updatedAt: (vm) => vm.eirElementInfo.updated_at,
    setId: (vm) => vm.$route.params.setId,
    eirId: (vm) => vm.$route.params.eirId,
    revitElements: (vm) => vm.getTestElementsById(vm.eirId),
    checking: (vm) => vm.getTestCheckStatusById(vm.eirId) === 2,
  },
  watch: {
    eirId() {
      this.init()
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    ...mapActions('eirElements', ['fetchEir']),
    async init() {
      this.isLoading = true

      await this.fetchEir(this.eirId)

      this.isLoading = false
    },
  },
}
</script>

<style scoped lang="scss">
.loader {
  position: absolute;
  left: 0;
  top: 0;
}

.desc {
  font-size: 14px;

  &--small {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
