<template>
  <div>
    <v-data-table
      class="custom-table"
      :headers="computedHeaders"
      :items="computedItems"
      :loading="isLoading"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      disable-sort
    >
      <template #item.param_type="{ item: { param_type: paramType } }">
        {{ { text: 'Текст', number: 'Число' }[paramType] }}
      </template>

      <template #item.is_required="{ item: { is_required: isRequired } }">
        {{ isRequired ? 'Да' : 'Нет' }}
      </template>

      <template
        #item.eirs_count="{
          item: { eir_elements: eirElements, eir_count: eirCount },
        }"
      >
        <DropdownMenu
          :items="eirElements"
          :items-count="eirCount"
          :link-path="`/eir-sets/${setId}/eirs`"
          :append="false"
        />
      </template>

      <template #item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-3"
              size="17"
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="$refs.modalSectionRemoveParam.show(item)"
            >
              mdi-folder-off-outline
            </v-icon>
          </template>
          <span>Отвязать раздел</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="17"
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteParam(item)"
            >
              mdi-link-off
            </v-icon>
          </template>
          <span>Отвязать параметр</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ModalSectionRemoveParam ref="modalSectionRemoveParam" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { PARAMS_HEADER_FIRST, PARAMS_HEADER_LAST } from '@/views/Eir/constants'
import { SAPR_FIELDS_LABELS } from '@/constants'

import DropdownMenu from '@/components/DropdownMenu'
import ModalSectionRemoveParam from './ModalSectionRemoveParam.vue'

export default {
  name: 'TabEirParams',
  components: { DropdownMenu, ModalSectionRemoveParam },
  data: () => ({
    isLoading: false,
    SAPR_FIELDS_LABELS,
  }),
  computed: {
    ...mapState('eirElements', ['eirElementInfo']),
    ...mapState('parameters', ['saprFields']),
    params: (vm) => vm.eirElementInfo?.parameters ?? [],
    paramsHeaders: (vm) =>
      vm.saprFields.map((param) => ({
        text: SAPR_FIELDS_LABELS[param.name],
        value: param.name,
      })),
    computedHeaders: (vm) => [
      ...PARAMS_HEADER_FIRST,
      ...vm.paramsHeaders,
      ...PARAMS_HEADER_LAST,
    ],
    computedItems: (vm) =>
      vm.params.map((param) => ({
        ...param,
        ...param.sapr_parameters.reduce(
          (acc, field) => ({
            ...acc,
            [field.name]: vm.prepareParameterValue(field.value),
          }),
          {}
        ),
      })),
    setId: (vm) => vm.$route.params.setId,
  },
  created() {
    this.fetchSaprFields()
  },
  methods: {
    ...mapActions('eirElements', ['removeParametersEirElement', 'fetchEir']),
    ...mapActions('parameters', ['fetchSaprFields']),
    async deleteParam({ id }) {
      if (!confirm('Отвязать параметр от требования?')) return

      this.isLoading = true

      await this.removeParametersEirElement({
        eirId: this.eirElementInfo.id,
        paramsIds: [{ id }],
      })
      await this.fetchEir(this.eirElementInfo.id)

      this.isLoading = false
    },
    prepareParameterValue(value) {
      if (typeof value === 'boolean') return value ? 'Да' : 'Нет'
      else return value
    },
  },
}
</script>
