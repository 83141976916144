<template>
  <v-dialog
    v-model="dialog"
    width="576"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            <div class="d-flex">
              <div style="word-break: break-word">
                Отвязка раздела от параметра &laquo;{{ paramName }}&raquo;
              </div>
              <div>
                <v-btn class="ml-auto" icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-title>
          <v-card-text class="d-flex">
            <v-autocomplete
              v-model="selectedSection"
              :items="sections"
              :menu-props="{ maxHeight: '400' }"
              :rules="[!!selectedSection || 'Выберите раздел']"
              :disabled="isLoading"
              item-text="name"
              item-value="id"
              label="Выберите раздел*"
              class="mr-4"
              clearable
              hide-details
              outlined
              dense
            />
          </v-card-text>
          <SaveCloseButtons
            :disabled="isLoading"
            btn-text-save="Отвязать"
            @close="dialog = false"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SaveCloseButtons from '@/components/SaveCloseButtons.vue'

export default {
  name: 'ModalSectionRemoveParam',
  components: { SaveCloseButtons },
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: true,
    selectedSection: null,
    paramId: null,
    paramName: null,
    sections: [],
  }),
  computed: {
    ...mapState('parameters', ['parameters']),
  },
  watch: {
    dialog(val) {
      if (val) return

      this.$refs.form.reset()
      this.paramId = null
      this.paramName = null
      this.sections = []
    },
  },
  methods: {
    ...mapActions('sections', [
      'fetchSections',
      'addParamsToSection',
      'removeParamsFromSection',
    ]),
    ...mapActions('parameters', ['fetchParams']),
    ...mapActions('eirElements', ['fetchEir']),
    async show(param) {
      if (!param) return

      this.paramId = param.id
      this.paramName = param.name
      this.sections = param.sections

      this.isLoading = true
      this.dialog = true

      await this.fetchSections({
        eir_set: +this.$route.params.setId,
      })

      this.isLoading = false
    },
    async submit() {
      if (!this.$refs.form.validate()) return

      this.isLoading = true

      await this.removeParamsFromSection({
        sectionId: this.selectedSection,
        paramsIds: [{ id: this.paramId }],
      })
      await this.fetchEir(this.$route.params.eirId)
      await this.fetchParams({
        filters: {
          eir_set: +this.$route.params.setId,
          eir: this.$route.params.eirId,
        },
      })

      this.isLoading = false
      this.dialog = false
    },
  },
}
</script>
