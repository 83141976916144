<template>
  <v-dialog
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            Привязка параметров
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="selected"
              :items="parameters"
              :menu-props="{ maxHeight: '400' }"
              :rules="[!!selected.length || 'Выберите параметр']"
              :disabled="isLoading"
              item-text="name"
              item-value="id"
              label="Выберите параметры*"
              multiple
              small-chips
              deletable-chips
              clearable
              hide-details
              outlined
              dense
            />
          </v-card-text>
          <SaveCloseButtons :disabled="isLoading" @close="dialog = false" />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SaveCloseButtons from '@/components/SaveCloseButtons'

export default {
  name: 'ModalEirParams',
  components: { SaveCloseButtons },
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: true,
    selected: [],
  }),
  computed: {
    ...mapState('parameters', ['parameters']),
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    ...mapActions('parameters', ['fetchParams']),
    ...mapActions('eirElements', ['bindParametersToEirElement', 'fetchEir']),

    async show() {
      this.dialog = true
      this.isLoading = true
      await this.fetchParams({
        filters: {
          eir_set: +this.$route.params.setId,
          not_in_eir: this.$route.params.eirId,
        },
      })
      this.isLoading = false
    },

    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true

        await this.bindParametersToEirElement({
          eirId: this.$route.params.eirId,
          paramsIds: this.selected.map((id) => ({ id })),
        })
        await this.fetchEir(this.$route.params.eirId)

        this.isLoading = false
        this.dialog = false
      }
    },
  },
}
</script>
