<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    content-class="guide_step_3-1"
    persistent
    width="476"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            {{ title }}
          </v-card-title>

          <v-card-text class="pa-3">
            <v-text-field
              v-model.trim="fields.name"
              label="Наименование*"
              :rules="[rules.required]"
              outlined
              dense
            />
            <v-textarea
              v-model.trim="fields.description"
              label="Описание"
              outlined
              dense
            />

            <div class="mt-6">*&nbsp;&mdash; обязательно</div>
          </v-card-text>

          <SaveCloseButtons
            :form-mode="formMode"
            :disabled="isLoading"
            @close="dialog = false"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import SaveCloseButtons from '@/components/SaveCloseButtons'

import rules from '@/mixins/rules'

export default {
  name: 'ModalEir',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    formMode: 'create',
    fields: {
      name: '',
      description: '',
      eir_set: '',
    },
  }),
  computed: {
    title: (vm) =>
      vm.formMode === 'create'
        ? 'Добавление требования'
        : 'Редактирование требования',
  },
  watch: {
    dialog(val) {
      if (!val) this.$refs.form.reset()
    },
  },
  methods: {
    ...mapActions('eirElements', [
      'fetchEirElements',
      'createEirElement',
      'updateEirElement',
      'fetchEir',
    ]),
    show(data = {}, formMode = 'create') {
      this.formMode = formMode

      if (formMode === 'update') this.fields = { ...data }

      this.dialog = true
    },
    async submit() {
      this.$refs.form.validate()
      if (!this.valid) return

      const setId = this.$route.params.setId
      const creation = this.formMode === 'create'
      const logMessage = creation
        ? 'Добавлено новое требование'
        : 'Требование изменено'

      this.isLoading = true

      if (creation)
        await this.createEirElement({ ...this.fields, eir_set: setId })
      else await this.updateEirElement(this.fields)

      await this.$logActionEvent(logMessage) // требование

      const eirId = this.$route.params.eirId

      if (!eirId) await this.fetchEirElements({ filters: { eir_set: setId } })
      else await this.fetchEir(eirId)

      this.isLoading = false
      this.$emit('updated')
      this.dialog = false
    },
  },
}
</script>
